import {filter} from "lodash";
import {Icon} from "@iconify/react";
import {sentenceCase} from "change-case";
import React, {useEffect, useState} from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import locationIcon from "@iconify/icons-carbon/location";
import emailIcon from "@iconify/icons-carbon/email";
import phoneIcon from "@iconify/icons-carbon/phone";
import websiteIcon from "@iconify/icons-eva/globe-fill";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  InputAdornment,
  Box,
  OutlinedInput,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {DataListHead, DataListToolbar, TableActionMenu} from "../components/_dashboard/user";
//

import AddEdit from "../components/_dashboard/vet/AddEdit";
import searchFill from "@iconify/icons-eva/search-fill";

export default function Vet() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, orderBy, order, filterName]);

  const getData = () => {
    window.axios
      .get("vets", {
        params: {
          limit: rowsPerPage,
          page,
          orderBy,
          orderDir: order,
          search: filterName,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.total);
        }
      })
      .catch(() => {
      });
  };

  const saveVet = (vet, setErrors, resetForm) => {
    new Promise((resolve, reject) => {
      const req =
        typeof vet.id !== "undefined" && vet.id
          ? window.axios.put("/vet/" + vet.id, vet)
          : window.axios.post("/vet", vet);

      req
        .then(({data}) => {
          setAddEditModal(false);
          getData();
          if (typeof resetForm === "function") {
            resetForm();
          }
        })
        .catch((error) => {
          let errors = {};
          for (let i in error.response.data) {
            if (error.response.data.hasOwnProperty(i)) {
              errors = {...errors, [i]: error.response.data[i]};
            }
          }
          setErrors(errors);
        });
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;

  const isDataNotFound = data.length === 0;

  const handleDeleteVet = (deleteModal) => {
    if (Array.isArray(deleteModal)) {
      window.axios
        .delete("vets", {data: deleteModal})
        .then((response) => {
          getData();
          setSelected([]);
        })
        .catch(() => {
        });
    } else if (typeof deleteModal.id !== "undefined" && deleteModal.id) {
      window.axios
        .delete("vet/" + deleteModal.id)
        .then((response) => {
          getData();
        })
        .catch(() => {
        });
    }
  };

  return (
    <Page title="Veterinari | SmartFarmer">
      <Container>
        {/*<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>*/}

          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={5}>
                <Typography variant="h4" gutterBottom>
                  Veterinari
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>

              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <OutlinedInput
                  sx={{ width: '100%', height: '100%'}}
                  value={filterName}
                  onChange={handleFilterByName}
                  placeholder="Caută..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{color: "text.disabled"}}/>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Box>
      </Container>

      <Container>
        <Box mb={3}>
        <Card>
          <CardContent>
          <Grid container spacing={3}>
            <Grid sx={{ p: 2 }} xs={12} md={4} lg={4}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
              <img src="/static/svg/doctors.svg" alt="doctors" />
            </Grid>
            <Grid sx={{ p: 2 }}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center" xs={12} md={8} lg={8}>
              <Typography alignItems="flex-end" align="center">
                <h2>Găsește veterinarul în localitatea ta</h2>
              </Typography>
              <br/>
              <Typography alignItems="flex-end" align="center">
                sau
              </Typography>
              <br/>
              <Button
                // sx={{ width: '100%', height: '100%'}}
                variant="contained"
                onClick={() => setAddEditModal(true)}
                startIcon={<Icon icon={plusFill}/>}
              >
                Adaugă un veterinar nou
              </Button>
            </Grid>

          </Grid>
          </CardContent>
        </Card>
        </Box>
      </Container>

      <Container>
        <Grid container spacing={3}>
          {data.map((vet) => (
            <Grid key={vet.id} item xs={12} sm={6} md={4}>
              <Card sx={{height: "100%"}}>
                <CardHeader
                  avatar={
                    <Avatar sx={{bgcolor: "#00a859"}} aria-label="recipe">
                      {vet.name.charAt(0)}
                    </Avatar>
                  }
                  title={vet.name}
                  subheader={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Icon icon={locationIcon}/>
                      &nbsp;&nbsp;{vet.address}
                    </div>
                  }
                />
                <CardContent>
                  {vet.email && (
                    <Typography variant="body2" color="text.secondary">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Icon icon={emailIcon}/> &nbsp;&nbsp; {vet.email}
                      </div>
                    </Typography>
                  )}

                  {vet.phone && (
                    <Typography variant="body2" color="text.secondary">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Icon icon={phoneIcon}/>
                        &nbsp;&nbsp; {vet.phone}
                      </div>
                    </Typography>
                  )}

                  {vet.website && (
                    <Typography variant="body2" color="text.secondary">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Icon icon={websiteIcon}/>
                        &nbsp;&nbsp; {vet.website}
                      </div>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box mt={3}>
          <Card>
            <TablePagination
              style={{display: "flex", justifyContent: "center"}}
              rowsPerPageOptions={[6, 12, 24, 48]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Veterinari pe pagină"}
            />
          </Card>
        </Box>
      </Container>
      <AddEdit open={addEditModal} close={() => setAddEditModal(false)} save={saveVet}/>

      <Dialog
        open={deleteModal !== false}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="delete-confirm-dialog-title"
        aria-describedby="delete-confirm-dialog-description"
      >
        <DialogTitle id="delete-confirm-dialog-title">
          {Array.isArray(deleteModal)
            ? "Sunteți siguri că doriți să ștergeți " + deleteModal.length + " veti ?"
            : typeof deleteModal.id !== "undefined" && deleteModal.id
              ? 'Sunteți siguri că doriți să ștergeți vetul "' + deleteModal.name + '"'
              : ""}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            M-am răzgândit
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              setDeleteModal(false);
              handleDeleteVet(deleteModal);
            }}
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
