import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { formatDistance } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  LinearProgress,
  CardContent
} from "@material-ui/core";

import Scrollbar from "../../Scrollbar";
import { useEffect, useState } from "react";

NewsItem.propTypes = {
  news: PropTypes.object.isRequired,
};

function NewsItem({ news }) {
  const { image, title, description, postedAt, link } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5 }} />
      <Box sx={{ minWidth: 240 }}>
        <Link href={link} target="_blank" color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          {description}
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}>
        {formatDistance(new Date(postedAt), new Date())}
      </Typography>
    </Stack>
  );
}

export default function AppNewsUpdate() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    window.axios
      .get("biofeed/posts")
      .then((response) => {
        if (response.status === 200) {
          setNews(response.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Card>
      <CardHeader title="Fii Smart" />
      {news.length === 0 && (
        <CardContent>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
        </CardContent>
      )}
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {news.map((n) => (
            <NewsItem key={n.title} news={n} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          href="https://biofeed.ro/fii-smart/"
          size="small"
          color="inherit"
          target="_blank"
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          Vezi mai multe
        </Button>
      </Box>
    </Card>
  );
}
