import * as Yup from 'yup';
import {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useFormik, Form, FormikProvider} from 'formik';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import {LoadingButton} from '@material-ui/lab';
import {useAuth} from "../../../context/auth";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {setAuthToken, setUser, authToken} = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Adresa de email este obligatorie'),
    password: Yup.string().required('Parola este obligatorie')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

      window.axios
        .post("login", {
          email: values.email,
          password: values.password
        })
        .then(response => {
          console.log(response);
          if (response && response.status === 200) {
            setAuthToken(response.data["token"]);
            setUser(response.data["user"]);
            navigate('/dashboard', {replace: true});
            // setError("");
          } else {
            setErrors({'email': 'Email sau parola gresita'});
          }
        })
        .catch(response => {
          console.log(response);
          setErrors({'email': 'Email sau parola gresita'});
        });


    }
  });

  const {errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setErrors} = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Parola"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember}/>}
            label="Autentificare la următoarea vizită"
          />

          {/*<Link component={RouterLink} variant="subtitle2" to="#">*/}
          {/*  Ai uitat parola?*/}
          {/*</Link>*/}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Autentificare
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
