// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  GeneratedRecipes,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  CalculatorWidget,
  AppCurrentSubject,
  AppConversionRates, Welcome, PartnersProducts
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard | Minimal-UI">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            <Welcome />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <GeneratedRecipes />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CalculatorWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppNewsUpdate />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PartnersProducts />
          </Grid>
          {/*<Grid item xs={12} sm={6} md={3}>*/}
          {/*  <AppNewUsers />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} sm={6} md={3}>*/}
          {/*  <AppItemOrders />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} sm={6} md={3}>*/}
          {/*  <AppBugReports />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <AppWebsiteVisits />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <AppCurrentVisits />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <AppConversionRates />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <AppCurrentSubject />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <AppOrderTimeline />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <AppTasks />*/}
          {/*</Grid>*/}
        </Grid>
      </Container>
    </Page>
  );
}
