import {
  Box,
  Grid,
  Container,
  CardHeader,
  Card,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
} from "@material-ui/core";
import Page from "../components/Page";
import phoneIcon from "@iconify/icons-carbon/phone";
import emailIcon from "@iconify/icons-carbon/email";

import React from "react";
import CloseIcon from "@iconify/icons-carbon/close";
import { Icon } from "@iconify/react";

export default function Contact() {
  return (
    <Page title="Contact | SmartFarmer">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader title="Contact" />
              <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                <Container maxWidth="xl">
                  <Grid container spacing={3}>
                    <Grid
                      sx={{ p: 2 }}
                      container
                      spacing={0}
                      // direction="column"
                      // alignItems="center"
                      // justifyContent="center"
                      xs={12}
                      md={7}
                      lg={7}
                    >
                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "#00a859" }}>
                              <Icon icon={phoneIcon} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Locație" secondary="Republica Moldova, or. Sângerei, str. Independenței 1" />
                        </ListItem>
                      </List>
                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "#00a859" }}>
                              <Icon icon={phoneIcon} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Telefon" secondary="+373 69455326" />
                        </ListItem>
                      </List>

                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "#00a859" }}>
                              <Icon icon={emailIcon} />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Email" secondary="office@smartfarmer.ro" />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      sx={{ p: 2 }}
                      xs={12}
                      md={5}
                      lg={5}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src="/static/svg/contact_us.svg" alt="metrics" />
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
