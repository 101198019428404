import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import calculatorIcon from '@iconify/icons-ant-design/calculator-filled';
import receiptIcon from '@iconify/icons-carbon/receipt';
import partnersIcon from "@iconify/icons-carbon/partnership";
import contactIcon from "@iconify/icons-carbon/phone";
import subscriptionIcon from "@iconify/icons-ic/outline-dynamic-feed";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'calculator',
    path: '/dashboard/calculator',
    icon: getIcon(calculatorIcon)
  },
  {
    title: 'Rețetele mele',
    path: '/dashboard/receipt',
    icon: getIcon(receiptIcon)
  },
  // {
  //   title: 'Abonamente',
  //   path: '/dashboard/subscription',
  //   icon: getIcon(subscriptionIcon)
  // },
  // {
  //   title: 'Contact',
  //   path: '/dashboard/contact',
  //   icon: getIcon(contactIcon)
  // },
  {
    title: "Parteneri",
    icon: getIcon(partnersIcon),
    children: [
      {
        title: "Furnizori",
        path: "/dashboard/suppliers",
      },
      {
        title: "Clienti",
        path: "/dashboard/clients",
      },
      {
        title: "Veterinari",
        path: "/dashboard/vets",
      },
    ],
  }
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
