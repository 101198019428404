import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { DataListHead, DataListToolbar, TableActionMenu } from "../components/_dashboard/user";
//

import AddEdit from "../components/_admin/indicator/AddEdit";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Nume", alignRight: false },
  { id: "unit", label: "Unitate", alignRight: false },
  { id: "" },
];

export default function Indicator() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, orderBy, order, filterName]);

  const getData = () => {
    window.axios
      .get("indicators", {
        params: {
          limit: rowsPerPage,
          page,
          orderBy,
          orderDir: order,
          search: filterName,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.total);
        }
      })
      .catch(() => {});
  };

  const saveIndicator = (indicator, setErrors, resetForm) => {
    new Promise((resolve, reject) => {
      const req =
        typeof indicator.id !== "undefined" && indicator.id
          ? window.axios.put("/indicator/" + indicator.id, indicator)
          : window.axios.post("/indicator", indicator);

      req
        .then(({ data }) => {
          setAddEditModal(false);
          getData();
          if (typeof resetForm === "function") {
            resetForm();
          }
        })
        .catch((error) => {
          let errors = {};
          for (let i in error.response.data) {
            if (error.response.data.hasOwnProperty(i)) {
              errors = { ...errors, [i]: error.response.data[i] };
            }
          }
          setErrors(errors);
        });
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;

  const isDataNotFound = data.length === 0;

  const handleDeleteIndicator = (deleteModal) => {
    if (Array.isArray(deleteModal)) {
      window.axios
        .delete("indicators", { data: deleteModal })
        .then((response) => {
          getData();
          setSelected([]);
        })
        .catch(() => {});
    } else if (typeof deleteModal.id !== "undefined" && deleteModal.id) {
      window.axios
        .delete("indicator/" + deleteModal.id)
        .then((response) => {
          getData();
        })
        .catch(() => {});
    }
  };

  return (
    <Page title="Indicatori | SmartFarmer">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Indicatori
          </Typography>
          <Button variant="contained" onClick={() => setAddEditModal(true)} startIcon={<Icon icon={plusFill} />}>
            Adaugă un indicator
          </Button>
        </Stack>

        <Card>
          <DataListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            bulkDelete={() => setDeleteModal(selected)}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DataListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {data.map((row) => {
                    const { id, name, unit } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {name}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {unit}
                        </TableCell>

                        <TableCell align="right">
                          <TableActionMenu
                            editAction={() => {
                              setAddEditModal(row);
                            }}
                            deleteAction={() => {
                              setDeleteModal(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isDataNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Indicatori pe pagină"}
          />
        </Card>
      </Container>
      <AddEdit open={addEditModal} close={() => setAddEditModal(false)} save={saveIndicator} />

      <Dialog
        open={deleteModal !== false}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="delete-confirm-dialog-title"
        aria-describedby="delete-confirm-dialog-description"
      >
        <DialogTitle id="delete-confirm-dialog-title">
          {Array.isArray(deleteModal)
            ? "Sunteți siguri că doriți să ștergeți " + deleteModal.length + " indicatori ?"
            : typeof deleteModal.id !== "undefined" && deleteModal.id
            ? 'Sunteți siguri că doriți să ștergeți indicatorul "' + deleteModal.name + '"'
            : ""}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            M-am răzgândit
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              setDeleteModal(false);
              handleDeleteIndicator(deleteModal);
            }}
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
