import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from './Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 200,
    zIndex: 1
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/svg/split_testing.svg"
                alt="suitcase"
              />
              <Typography variant="h4" className={classes.title}>
                Sistem unic de calcul a deficitului de elemente și vitamine în hrana animalelor.
              </Typography>
              <Typography variant="h6">
                Reieșind din alimentația pe care o oferiți animalelor, sistemul nostru va calcula cantitatea de microelemnte pe care o primește fiecare individ și devierile de la norma zilnică.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/svg/bear_market.svg"
                alt="graph"
              />
              <Typography variant="h4" className={classes.title}>
                Reduce pe cît e posibil cheltuielele pentru furaje.
              </Typography>
              <Typography variant="h6">
                Datorită rețetei personalizate care este generată de instrumentul nostru, aveți posibilitatea de a crea un premix unic care ar conține doar microelementele de care are nevoie animalul și în cantitățile calculate.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/svg/export_files.svg"
                alt="clock"
              />
              <Typography variant="h4" className={classes.title}>
                Interfață accesibilă și posibilitatea de a exporta în PDF.
              </Typography>
              <Typography variant="h6">
                Necătînd la faptul că sistemul e unul complex, am avut grijă să creăm o interfață accesibilă și intuitivă. La fel oferim posibilitatea de a exporta rețetele generate în format PDF.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
