import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from "@material-ui/core";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import TextDivider from "../../components/TextDivider";
import sidebarAdminConfig from "./SidebarAdminConfig";
import { useAuth } from "../../context/auth";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": { height: "100%", display: "flex", flexDirection: "column" },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {user.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {user.roles && user.roles.includes("ROLE_ADMIN") ? 'Administrator' : ''}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />
      {user.roles && user.roles.includes("ROLE_ADMIN") && (
        <>
          <Box mt={3}>
            <TextDivider>Admin Section</TextDivider>
          </Box>
          <NavSection navConfig={sidebarAdminConfig} />
        </>
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/*<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>*/}
      {/*  <Stack*/}
      {/*    alignItems="center"*/}
      {/*    spacing={3}*/}
      {/*    sx={{*/}
      {/*      p: 2.5,*/}
      {/*      pt: 5,*/}
      {/*      borderRadius: 2,*/}
      {/*      position: 'relative',*/}
      {/*      bgcolor: 'grey.200'*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      component="img"*/}
      {/*      src="/static/illustrations/illustration_rocket.png"*/}
      {/*      sx={{ width: 100, position: 'absolute', top: -50 }}*/}
      {/*    />*/}

      {/*    <Box sx={{ textAlign: 'center' }}>*/}
      {/*      <Typography gutterBottom variant="h6">*/}
      {/*        Get more?*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
      {/*        From only $69*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}

      {/*    <Button*/}
      {/*      fullWidth*/}
      {/*      href="https://material-ui.com/store/items/minimal-dashboard/"*/}
      {/*      target="_blank"*/}
      {/*      variant="contained"*/}
      {/*    >*/}
      {/*      Upgrade to Pro*/}
      {/*    </Button>*/}
      {/*  </Stack>*/}
      {/*</Box>*/}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
