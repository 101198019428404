import {
  Card,
  CardHeader,
  Box,
  Grid,
  Container,
  Avatar,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import { withFormik } from "formik";
import * as Yup from "yup";

const StepOne = ({ values, handleChange, handleSubmit, errors, touched }) => {
  const [animals, setAnimals] = useState([]);
  const [animalTypes, setAnimalTypes] = useState([]);

  useEffect(() => {
    getAnimalTypes();
    getAnimals()
  }, []);

  const getAnimals = () => {
    window.axios
      .get("animals")
      .then((response) => {
        if (response.status === 200) {
          setAnimals(response.data.data);
        }
      })
      .catch(() => {});
  };

  const getAnimalTypes = () => {
    window.axios
      .get("animalTypes", {
        params: {
          orderBy: "id",
          orderDir: "asc",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAnimalTypes(response.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "white", border: "3px solid #637381", color: "#637381", fontWeight: "bold" }}
            aria-label="recipe"
          >
            1
          </Avatar>
        }
        title="Specificațiile animalelor"
        subheader="Specifica tipul animalelor și apasă 'Adaugă'"
      />
      <Box sx={{ p: 3, pb: 3 }} dir="ltr">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid xs={12} md={4} lg={4} item direction="column" alignItems="center" justifyContent="center">
              <FormControl component="fieldset" fullWidth>
                <TextField
                  select
                  name="animalTypeId"
                  margin="dense"
                  variant="outlined"
                  label="Specia"
                  value={values.animalTypeId}
                  onChange={handleChange}
                  error={!!errors.animalTypeId && touched.animalTypeId}
                  helperText={!!errors.animalTypeId && touched.animalTypeId ? errors.animalTypeId : ""}
                  required
                >
                  {animalTypes.map((animalType) => (
                    <MenuItem key={animalType.id} value={animalType.id}>
                      {animalType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid xs={12} md={4} lg={4} item direction="column" alignItems="center" justifyContent="center">
              <FormControl component="fieldset" fullWidth>
                <TextField
                  select
                  name="animalId"
                  margin="dense"
                  variant="outlined"
                  label="Criteriul Econimic"
                  value={values.animalId}
                  onChange={handleChange}
                  error={!!errors.animalId && touched.animalId}
                  helperText={!!errors.animalId && touched.animalId ? errors.animalId : ""}
                  required
                >
                  {(values.animalTypeId > 0 ? animals.filter((a) => a.animalTypeId === values.animalTypeId) : []).map((animalType) => (
                    <MenuItem key={animalType.id} value={animalType.id}>
                      {animalType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid xs={12} md={2} lg={2} item direction="column" alignItems="center" justifyContent="center">
              <TextField
                fullWidth={true}
                name="animalCount"
                label="Numarul de animale"
                margin="dense"
                variant="outlined"
                value={values.animalCount}
                onChange={handleChange}
                required
                error={!!errors.animalCount && touched.animalCount}
                helperText={!!errors.animalCount && touched.animalCount ? errors.animalCount : ""}
              />
            </Grid>
            <Grid xs={12} md={2} lg={2} mt={1} item direction="column" alignItems="center" justifyContent="center">
              <FormControl fullWidth>
                <Button onClick={handleSubmit} variant="contained" style={{ padding: 15 }}>
                  Adaugă
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Card>
  );
};

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    animalTypeId: "",
    animalId: "",
    animalCount: "",
  }),
  validationSchema: Yup.object().shape({
    animalTypeId: Yup.string().required('Selectati specia'),
    animalId: Yup.string().required('Selectati criteriu economic'),
    animalCount: Yup.string().required('Introduceti numarul de animale pentru care faceti calculul')
  }),
  handleSubmit(values, { props, setErrors, resetForm }) {
    props.add(values, setErrors, resetForm);
  },
})(StepOne);

export default FormikApp;
