import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
// material
import {
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Button
} from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import React from "react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import {Link as RouterLink} from "react-router-dom";

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    value: faker.datatype.number(),
    icon: <Icon icon={facebookFill} color="#1877F2" width={32} height={32} />
  },
  {
    name: 'Google',
    value: faker.datatype.number(),
    icon: <Icon icon={googleFill} color="#DF3E30" width={32} height={32} />
  },
  {
    name: 'Linkedin',
    value: faker.datatype.number(),
    icon: <Icon icon={linkedinFill} color="#006097" width={32} height={32} />
  },
  {
    name: 'Twitter',
    value: faker.datatype.number(),
    icon: <Icon icon={twitterFill} color="#1C9CEA" width={32} height={32} />
  }
];

// ----------------------------------------------------------------------

SiteItem.propTypes = {
  site: PropTypes.object
};

function SiteItem({ site }) {
  const { icon, value, name } = site;

  return (
    <Grid item xs={6}>
      <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
        <Box sx={{ mb: 0.5 }}>{icon}</Box>
        <Typography variant="h6">{fShortenNumber(value)}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {name}
        </Typography>
      </Paper>
    </Grid>
  );
}

export default function CalculatorWidget() {
  return (
    <Card style={{height: "100%"}}>
      <CardHeader title="Calculator" />
      <CardContent>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                container
                spacing={0}
                xs={12}
                md={6}
                lg={6}
                alignItems="center"
                justifyContent="center"
              >

                <Typography alignItems="flex-end"
                  // align="center"
                >
                  Calculează necesitățile zilnice de macro-, micro- elemente și vitamine și află de ce duce lipsă animalul.
                  Generează o rețetă unică pentru a suplini devierile de la necesitățile zilnice.
                </Typography>
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
                container
              >
                <img src="/static/svg/calculator.svg" alt="graph" />
              </Grid>
            </Grid>
          </Container>
      </CardContent>
      <Box position="absolute" bottom="0px"  sx={{ width: 1 }}>
      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          to="/dashboard/calculator"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          Calculează acum
        </Button>
      </Box>
      </Box>
    </Card>
  );
}
