import { Icon } from '@iconify/react';
import receiptIcon from '@iconify/icons-carbon/receipt';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {Card, CircularProgress, Typography} from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import {useEffect, useState} from "react";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  height: "100%",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

export default function GeneratedRecipes() {
  const [recipes, setRecipes] = useState(null);

  useEffect(() => {
    getRecipes();
  }, []);

  const getRecipes = () => {
    window.axios
      .get("/recipes")
      .then((response) => {
        if (response.status === 200) {
          setRecipes(response.data.total);
        }
      })
      .catch(() => {});
  };

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={receiptIcon} width={24} height={24} />
      </IconWrapperStyle>
      {recipes === null ? <CircularProgress /> : <Typography variant="h3">{fShortenNumber(recipes)}</Typography> }
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Rețete Generate
      </Typography>
    </RootStyle>
  );
}
