// material
import { Box, Grid, Container, Typography } from "@material-ui/core";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  CalculatorWidget,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
import CalculareRegimAlimentar from "../components/_dashboard/calculator/CalculareRegimAlimentar";
import StepOne from "../components/_dashboard/calculator/StepOne";
import { useState } from "react";
import StepTwo from "../components/_dashboard/calculator/StepTwo";
import _ from "lodash";
import DailyNormMicroMacro from "../components/_dashboard/calculator/DailyNormMicroMacro";

// ----------------------------------------------------------------------

export default function Calculator() {
  const [stepOneValues, setStepOneValues] = useState([]);
  const [stepTwoValues, setStepTwoValues] = useState([]);

  return (
    <Page title="Calculator | SmartFarmer">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <CalculareRegimAlimentar />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <StepOne
              add={(values) => {
                setStepOneValues(values);
              }}
            />
          </Grid>

          {stepOneValues.animalTypeId !== undefined && (
            <Grid item xs={12} md={12} lg={12}>
              <StepTwo
                add={(values) => {
                  let stepTwoValuesClone = _.clone(stepTwoValues);
                  setStepTwoValues(stepTwoValuesClone.concat([values]));
                }}
              />
            </Grid>
          )}
          {stepOneValues.animalTypeId !== undefined && stepTwoValues.length > 0 && (

              <DailyNormMicroMacro animal={stepOneValues} forages={stepTwoValues}/>

          )}
        </Grid>
      </Container>
    </Page>
  );
}
