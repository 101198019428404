import React from 'react';


import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from '../components/Page';
import ProductHero from "../components/landing/ProductHero";
import ProductValues from "../components/landing/ProductValues";
import ProductHowItWorks from "../components/landing/ProductHowItWorks";
import Footer from "../components/landing/Footer";
import {
  Avatar,
  Box,
  Button,
  Card, CardContent,
  CardHeader,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

const RootStyle = styled(Page)(({ theme }) => ({

}));

export default function About() {
  return (
    <RootStyle title="Smart Farmer - Sporeste productivitatea afacerii tale">
      <React.Fragment>

          <Container maxWidth="lg">
            <Box mt={15} mb={5}>
            <Card>
              <CardHeader
                title="Soluții optime pentru afacerea ta!"
                subheader="Această platformă a fost creată pentru tine!"
              />

              <CardContent>
                <Grid container spacing={3}>
                <Grid sx={{ p: 2 }}
                      // container
                      // spacing={0}
                      // direction="column"
                      // alignItems="center"
                      // justifyContent="center"
                      xs={12} md={8} lg={8}>
                  <Typography >Noi venim cu soluții reale pentru reducerea costurilor de producție și creșterea productivității!</Typography><br/>
                  <Typography>Sistemul inteligent de calcul reduce la minim costurile pentru furaje și asigură alimentarea animalelor și păsărilor cu necesarul de micro și macro elemente necesare pentru productivitatea lor maximă și calitatea înaltă a produsului final.</Typography><br/>
                  <Typography >Platforma noastră conține:</Typography>
                  <ul>
                    <li style={{marginLeft: 50}}>baza de date a fermierilor beneficiari ai platformei;</li>
                    <li style={{marginLeft: 50}}>baza de date a specialiștilor veterinari cu posibilitatea de accesare a consultanței lor;</li>
                    <li style={{marginLeft: 50}}>baza de date a furnizorilor de furaje, premixuri și aditivi alimentari;</li>
                    <li style={{marginLeft: 50}}>articole și practici privind alimentația corectă a animalelor și păsărilor;</li>
                    <li style={{marginLeft: 50}}>baza de date a raselor de animale și a păsărilor de curte crescute în condițiile climaterice ale Republicii Moldova;</li>
                    <li style={{marginLeft: 50}}>necesarul zilnic de micro, macroelemente și vitamine în funcție de rasa animalului/pasărea, populația animalelor și păsărilor, disponibilitatea de furaje și criteriul economic.</li>
                  </ul>
                </Grid>
                <Grid sx={{ p: 2 }} xs={12} md={4} lg={4}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center">
                  <img src="/static/svg/progressive_app.svg" alt="doctors" />
                </Grid>
                  </Grid>
              </CardContent>

            </Card>
            </Box>
          </Container>


        <Footer />
      </React.Fragment>
    </RootStyle>
  );
}
