import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import axios from "axios";
import "typeface-roboto";

window.axios = axios.create();
window.axios.CancelToken = axios.CancelToken;
window.axios.isCancel = axios.isCancel;

window.axios.defaults.headers.common = {
  Authorization: "Bearer " + localStorage.getItem("_token"),
};

window.axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
reportWebVitals();
