import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";

import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@iconify/icons-carbon/close";
import RemoveIcon from "@iconify/icons-carbon/subtract";
import AddIcon from "@iconify/icons-carbon/add";
import { Icon } from "@iconify/react";
import { withFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import OutlinedDiv from "../../OutlinedDiv";
import TextDivider from "../../TextDivider";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const AddEditAnimal = (props) => {
  const classes = useStyles();
  const { open, close, handleSubmit, values, handleChange, touched, errors, setFieldValue } = props;

  const [animalTypes, setAnimalTypes] = useState([]);
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    getAnimalTypes();
    getIndicatorsTypes();
  }, []);

  const getAnimalTypes = () => {
    window.axios
      .get("animalTypes")
      .then((response) => {
        if (response.status === 200) {
          setAnimalTypes(response.data.data);
        }
      })
      .catch(() => {});
  };

  const getIndicatorsTypes = () => {
    window.axios
      .get("indicators")
      .then((response) => {
        if (response.status === 200) {
          setIndicators(response.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Dialog
      open={props.open !== false}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">
        {typeof open.id !== "undefined" ? 'Editează animalul "' + open.name + '"' : "Animal nou"}
        <IconButton
          aria-label="close"
          onClick={props.close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon={CloseIcon} width={22} height={22} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" fullWidth>
                <TextField
                  name="name"
                  label="Nume categorie animal"
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && touched.name}
                  helperText={!!errors.name && touched.name ? errors.name : "ex: Prepelițe (1-4 săptămîni)"}
                  required
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <TextField
                  select
                  name="animalTypeId"
                  margin="dense"
                  variant="outlined"
                  label="Selecteaza tipul de animal"
                  value={values.animalTypeId}
                  onChange={handleChange}
                  error={!!errors.animalTypeId && touched.animalTypeId}
                  helperText={!!errors.animalTypeId && touched.animalTypeId ? errors.animalTypeId : ""}
                  required
                >
                  {animalTypes.map((animalType) => (
                    <MenuItem key={animalType.id} value={animalType.id}>
                      {animalType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <TextDivider>Necesitățile animalului</TextDivider>
                {values.animalNeeds &&
                  values.animalNeeds.map((animalNeed, index) => (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <FormControl component="fieldset" fullWidth>
                          <TextField
                            select
                            name="animalTypeId"
                            margin="dense"
                            variant="outlined"
                            label="Selecteaza un indicator"
                            value={animalNeed.indicatorId}
                            onChange={(e) => {
                              setFieldValue("animalNeeds[" + index + "]", {
                                indicatorId: e.target.value,
                                quantity: values.animalNeeds[index].quantity ?? "",
                              });
                            }}
                            error={!!errors.animalTypeId && touched.animalTypeId}
                            helperText={!!errors.animalTypeId && touched.animalTypeId ? errors.animalTypeId : ""}
                            required
                          >
                            {indicators.map((indicator) => (
                              <MenuItem
                                disabled={values.animalNeeds.map((need) => need.indicatorId).includes(indicator.id)}
                                key={indicator.id}
                                value={indicator.id}
                              >
                                {indicator.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} sm={3}>
                        <FormControl component="fieldset" fullWidth>
                          <Box display="flex" justifyContent="space-between">
                            <Box flexGrow={1}>
                              <TextField
                                fullWidth={true}
                                name="quantity"
                                label="Cantitate"
                                margin="dense"
                                variant="outlined"
                                value={animalNeed.quantity}
                                onChange={(e) =>
                                  setFieldValue("animalNeeds[" + index + "]", {
                                    indicatorId: values.animalNeeds[index].indicatorId ?? "",
                                    quantity: e.target.value,
                                  })
                                }
                                error={
                                  !!errors.animalNeed &&
                                  !!errors.animalNeed.quantity &&
                                  errors.animalNeed.quantity[index] &&
                                  touched.animalNeed.quantity[index]
                                }
                                helperText={
                                  !!errors.animalNeed &&
                                  !!errors.animalNeed.quantity &&
                                  errors.animalNeed.quantity[index] &&
                                  touched.animalNeed &&
                                  touched.animalNeed.quantity[index]
                                    ? errors.animalNeed.quantity[index]
                                    : ""
                                }
                              />
                            </Box>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <FormControl component="fieldset" fullWidth>
                          <Box display="flex" justifyContent="space-between">
                            <Box flexGrow={1}>
                              <TextField
                                fullWidth={true}
                                name="quantity"
                                label="Unitatea"
                                margin="dense"
                                variant="outlined"
                                value={
                                  indicators.find((o) => {
                                    return o.id === values.animalNeeds[index].indicatorId ?? -1;
                                  })
                                    ? indicators.find((o) => {
                                        return o.id === values.animalNeeds[index].indicatorId ?? -1;
                                      }).unit
                                    : "-"
                                }
                                disabled={true}
                              />
                            </Box>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={2} spacing={0}>
                        <Box pt={2} textAlign="center">
                          {index === 0 ? (
                            <Button
                              onClick={() => {
                                setFieldValue("animalNeeds", values.animalNeeds.concat([{ quantity: '', indicatorId: '' }]));
                              }}
                            >
                              <Icon icon={AddIcon} width={22} height={22} />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                let animalNeeds = _.clone(values.animalNeeds);
                                animalNeeds.splice(
                                  index,
                                  1
                                );
                                setFieldValue("animalNeeds", animalNeeds);
                              }}
                            >
                              <Icon icon={RemoveIcon} width={22} height={22} />
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Salvează
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ open }) => ({
    id: open.id || "",
    name: open.name || "",
    animalTypeId: open.animalTypeId || "",
    animalNeeds: open.animalNeeds || [{ quantity: '', indicatorId: '' }],
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit(values, { props, setErrors, resetForm }) {
    props.save(values, setErrors, resetForm);
  },
})(AddEditAnimal);

export default FormikApp;
