import {Box, Grid, Card, Paper, Typography, CardHeader, CardContent, Divider, Button} from "@material-ui/core";
import ShopProductCard from "../products/ProductCard";
import faker from "faker";
import { mockImgProduct } from "../../../utils/mockImages";
import { sample } from "lodash";
import {Icon} from "@iconify/react";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import {useEffect, useState} from "react";

export default function PartnersProducts() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    window.axios
      .get("biofeed/products")
      .then((response) => {
        if (response.status === 200) {
          setProducts(response.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Card>
      <CardHeader title="Produse Parteneri" />
      <CardContent>
        <Grid container spacing={3}>
          {products.map((product) => (
            <Grid key={product.id} item xs={6} sm={6} md={3}>
              <ShopProductCard product={product} />
            </Grid>
          ))}
        </Grid>

      </CardContent>
      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          href="https://biofeed.ro/produse/"
          size="small"
          color="inherit"
          target="_blank"
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          Vezi mai multe
        </Button>
      </Box>
    </Card>
  );
}
