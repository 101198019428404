import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    border: {
        borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
        width: "100%"
    },
    content: {
        padding: "0 10px 0 10px",
        whiteSpace: "nowrap",
      color: "#637381",
      fontSize: '0.875rem'
    }
}));

const TextDivider = props => {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <div className={classes.border} />
            <span className={classes.content}>{props.children}</span>
            <div className={classes.border} />
        </Box>
    );
};

export default TextDivider;
