import {Card, CardHeader, Box, Grid, Container, Typography} from '@material-ui/core';
import React from "react";


export default function CalculareRegimAlimentar() {


  return (
    <Card>
      <CardHeader title="Calcularea regimului alimentar" />
      <Box sx={{ p: 3, pb: 3 }} dir="ltr">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid sx={{ p: 2 }} xs={12} md={4} lg={4}
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center">
              <img src="/static/svg/metrics.svg" alt="metrics" />
            </Grid>
            <Grid sx={{ p: 2 }}
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center" xs={12} md={8} lg={8}>
              <Typography alignItems="flex-end" align="center">

                  Cum să creezi un regim alimentar perfect pentru animale în scop industrial? Este o problemă mult mai gravă decît poate părea la prima vedere. Acest instrument vine să ofere soluția în scopul dat. Acest sistem calculeaza devierile de la necesitățile zilnice de elemente pe care le primesc animalele conform regimului alimentaro-nutrițional și generează o rețetă unică pentru a le suplini.

              </Typography>
            </Grid>

          </Grid>
        </Container>

      </Box>
    </Card>
  );
}
