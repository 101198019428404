import PropTypes from 'prop-types';

import { Paper, Typography } from '@material-ui/core';

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Nu s-a găsit nimic
      </Typography>
      <Typography variant="body2" align="center">
        0 rezultate pentru &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Verifică dacă ai scris corect termenii.
      </Typography>
    </Paper>
  );
}
