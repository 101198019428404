import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "./Button";
import Typography from "./Typography";
import { Icon } from "@iconify/react";
import bulbIcon from "@iconify/icons-ant-design/bulb-outline";
import calculatorIcon from "@iconify/icons-ant-design/calculator-outline";
import chartIcon from "@iconify/icons-ant-design/line-chart-outline";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.primary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(5),
    color: 'white'
  },
  number: {
    background: "none repeat scroll 0 0 #FFFFFF",
    borderRadius: "100%",
    inlineBlock: "inline-block",
    height: "80px",
    width: "80px",
    position: "relative",
    padding: "20px",
    marginBottom: theme.spacing(3),
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Cum te ajuta Smart Farmer
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>
                  <Icon icon={bulbIcon} width={40} height={40} />
                </div>
                <Typography color="white" variant="h5" align="center">
                  Calculează necesitățile zilnice de macro-, micro- elemente și vitamine și află de ce duce lipsă
                  animalul.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>
                  <Icon icon={calculatorIcon} width={40} height={40} />
                </div>
                <Typography color="white" variant="h5" align="center">
                  Reduce costurile furajelor la minim și crește profitabilitatea afacerii!
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>
                  <Icon icon={chartIcon} width={40} height={40} />
                </div>
                <Typography color="white" variant="h5" align="center">
                  Planifică și organizează-ți afacerea împreună cu noi!
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  size="large"*/}
        {/*  variant="contained"*/}
        {/*  className={classes.button}*/}
        {/*  component="a"*/}
        {/*  href="/premium-themes/onepirate/sign-up/"*/}
        {/*>*/}
        {/*  Get started*/}
        {/*</Button>*/}
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
