import React from "react";
import {
  Box, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormGroup, FormLabel, Grid,
  IconButton, TextField
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@iconify/icons-carbon/close";
import {Icon} from "@iconify/react";
import {withFormik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";


const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

const AddEdit = (props) => {
    const classes = useStyles();
    const {open, close, handleSubmit, values, handleChange, touched, errors} = props;

    return (
      <Dialog
        open={props.open !== false}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {typeof open.id !== "undefined" ? 'Editează client "' + open.name + '"' : "Client nou"}
          <IconButton
            aria-label="close"
            onClick={props.close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon={CloseIcon} width={22} height={22} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset" fullWidth>
                  <TextField
                    name="name"
                    label="Nume client"
                    margin="dense"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    error={!!errors.name && touched.name}
                    helperText={!!errors.name && touched.name ? errors.name : ""}
                    required
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <TextField
                    name="address"
                    label="Adresa"
                    margin="dense"
                    variant="outlined"
                    value={values.address}
                    onChange={handleChange}
                    error={!!errors.address && touched.address}
                    helperText={!!errors.address && touched.address ? errors.address : ""}
                    required
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <TextField
                    name="email"
                    label="Email"
                    margin="dense"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={!!errors.email && touched.email}
                    helperText={!!errors.email && touched.email ? errors.email : ""}
                    required
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <TextField
                    name="phone"
                    label="Telefon"
                    margin="dense"
                    variant="outlined"
                    value={values.phone}
                    onChange={handleChange}
                    error={!!errors.phone && touched.phone}
                    helperText={!!errors.phone && touched.phone ? errors.phone : ""}
                    required
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth>
                  <TextField
                    name="website"
                    label="Site"
                    margin="dense"
                    variant="outlined"
                    value={values.website}
                    onChange={handleChange}
                    error={!!errors.website && touched.website}
                    helperText={!!errors.website && touched.website ? errors.website : ""}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Salvează
            </Button>
        </DialogActions>
      </Dialog>
    );
}

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ open }) => ({
    id: open.id || "",
    name: open.name || "",
    address: open.address || "",
    phone: open.phone || "",
    email: open.email || "",
    website: open.website || "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    address: Yup.string().required(),
  }),
  handleSubmit(values, { props, setErrors, resetForm }) {
    props.save(values, setErrors, resetForm);
  },
})(AddEdit);

export default FormikApp;
