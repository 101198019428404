import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-carbon/login';
import registerIcon from '@iconify/icons-carbon/user-avatar-filled';
import aboutIcon from '@iconify/icons-carbon/text-align-left';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarLandingConfig = [
  {
    title: 'Autentificare',
    path: '/login',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Înregistrare',
    path: '/register',
    icon: getIcon(registerIcon)
  },
  {
    title: 'Despre noi',
    path: '/about',
    icon: getIcon(aboutIcon)
  }
];

export default sidebarLandingConfig;
