// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'


import { AuthContext } from "./context/auth";
import ReactGA from 'react-ga';


const TRACKING_ID = "UA-212999712-1";
ReactGA.initialize(TRACKING_ID, { debug: true });
ReactGA.pageview(window.location.pathname);

export default function App() {
  const navigate = useNavigate()
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [navigate]);

  window.axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && 401 === error.response.status) {
        setAuthToken(null);
        setUser({});
      } else {
        return Promise.reject(error);
      }
    }
  );

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState({});

  const existingToken = localStorage.getItem("_token");
  const [authToken, setAuthToken] = useState(existingToken);

  const [userLoading, setUserLoading] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setUserLoading(true);

    if (existingToken !== null) {
      window.axios.defaults.headers.common = {
        Authorization: "Bearer " + existingToken,
      };
    }

    window.axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    window.axios
      .get("user")
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .then(() => setUserLoading(false))
      .catch(() => {
        setToken(null);
        setUserLoading(false);
      });
  }, [existingToken]);

  const setToken = (data) => {
    const token = data;
    localStorage.setItem("_token", token);

    window.axios.defaults.headers.common = {
      Authorization: token,
    };

    setAuthToken(data);
  };

  const setLoggedUser = (data) => {
    setUser(data);
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        user,
        setAuthToken: setToken,
        setUser: setLoggedUser,
      }}
    >
      {/*<RouteChangeTracker/>*/}
      <ThemeConfig>
        {/*<ScrollToTop />*/}
        <Router />
      </ThemeConfig>
    </AuthContext.Provider>
  );
}
