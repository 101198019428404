import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, ListItem } from "@material-ui/core";
// components
import { MHidden } from "../../components/@material-extend";
//

import withStyles from "@material-ui/styles/withStyles";
import LanguagePopover from "../../layouts/dashboard/LanguagePopover";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../Logo";
const styles = (theme) => ({});

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {},
}));

const ToolBarHeight = styled(Box)(({ theme }) => ({
  marginBottom: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    marginBottom: APPBAR_DESKTOP,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

LandingNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

function LandingNavbar({ onOpenSidebar, classes }) {
  return (
    <>
      <RootStyle>
        <ToolbarStyle position="fixed">
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {/*<Searchbar />*/}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="lgDown">
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
              {/*<LanguagePopover />*/}
              <ListItem
                style={{ color: "#212B36" }}
                button
                to="/login"
                disableGutters
                component={RouterLink}
                sx={{
                  py: 1.5,
                  px: 2.5,
                  mt: "1px",
                }}
              >
                Autentificare
              </ListItem>
              <ListItem
                style={{ color: "#212B36" }}
                button
                to="/register"
                disableGutters
                component={RouterLink}
                sx={{
                  py: 1.5,
                  px: 2.5,
                  mt: "1px",
                }}
              >
                Înregistrare
              </ListItem>
              <ListItem
                style={{ color: "#212B36" }}
                button
                to="/about"
                disableGutters
                component={RouterLink}
                sx={{
                  py: 1.5,
                  px: 2.5,
                  mt: "1px",
                }}
              >
                Despre noi
              </ListItem>
              {/*<NotificationsPopover />*/}
              {/*<AccountPopover />*/}
            </Stack>
          </MHidden>
        </ToolbarStyle>
      </RootStyle>
      <ToolBarHeight></ToolBarHeight>
    </>
  );
}

export default withStyles(styles)(LandingNavbar);
