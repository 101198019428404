// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Link,
  GlobalStyles,
  CssBaseline,
  Toolbar,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Button,
} from "@material-ui/core";
// components
import starFill from "@iconify/icons-eva/star-outline";
import { Icon } from "@iconify/react";
import React from "react";
import Page from "../components/Page";
import { Link as RouterLink } from "react-router-dom";

const tiers = [
  {
    title: "Gratuit",
    price: "0",
    description: [
      "Beneficiaza de acces gratuit 15 zile, fara obligatii, la pachetul Gold pentru a determina care sunt necesitatile afacerii tale",
    ],
    buttonText: "Înregistrează-te acum",
    buttonVariant: "outlined",
    linkTo: "/register",
  },
  {
    title: "Pro",
    subheader: "Cel mai popular",
    price: "2.99",
    description: ["5 generări de rețete", "Ajutor email"],
    buttonText: "Contactează-ne",
    buttonVariant: "contained",
    linkTo: "/dashboard/contact",
  },
  {
    title: "Gold",
    price: "5.99",
    description: [
      "50 generări de rețete",
      "Ajutor prioritar email/telefon",
      "Consultanță specialiști",
      "Promovarea produselor",
    ],
    buttonText: "Contactează-ne",
    buttonVariant: "outlined",
    linkTo: "/dashboard/contact",
  },
];

export default function Subscription() {
  return (
    <Page title="Abonamente | SmartFarmer">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <>
              <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid
                      sx={{ px: 15, py: 2 }}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      xs={12}
                      md={8}
                      lg={8}
                    >
                      <Typography alignItems="flex-end" align="center">
                        <h2>Toate beneficiile SmartFarmer pentru afacerea ta</h2>
                      </Typography>
                      <br />
                      <Typography alignItems="flex-end" align="center">
                        Abonamentele individuale SmartFarmer iti ofera acces la servicii de calitate, pentru a spori
                        randamentul afacerii tale.
                      </Typography>
                      <br />
                      <Typography alignItems="flex-end" align="center">
                        <b>Alege abonamentul care se potriveste nevoilor tale</b>
                      </Typography>
                      <br />
                    </Grid>
                    <Grid
                      sx={{ p: 2 }}
                      xs={12}
                      md={3}
                      lg={3}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src="/static/svg/post.svg" alt="doctors" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Container maxWidth="md" component="main" sx={{ mt: 4 }}>
                <Grid container spacing={5} alignItems="flex-end">
                  {tiers.map((tier) => (
                    <Grid item key={tier.title} xs={12} sm={tier.title === "Enterprise" ? 12 : 6} md={4}>
                      <Card>
                        <CardHeader
                          title={tier.title}
                          subheader={
                            <Typography align="center" sx={{ color: "white" }}>
                              {tier.subheader}
                            </Typography>
                          }
                          titleTypographyProps={{ align: "center" }}
                          action={tier.title === "Pro" ? <Icon width={22} height={22} icon={starFill} /> : null}
                          subheaderTypographyProps={{
                            align: "center",
                          }}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "light" ? theme.palette.primary.dark : theme.palette.grey[700],
                            padding: 2,
                            color: "white",
                          }}
                        />
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "baseline",
                              mb: 2,
                            }}
                          >
                            {tier.price > 0 && (
                              <>
                                <Typography component="h2" variant="h3" color="text.primary">
                                  € {tier.price}
                                </Typography>
                                <Typography variant="h6" color="text.secondary">
                                  /lună
                                </Typography>
                              </>
                            )}
                          </Box>
                          <ul>
                            {tier.description.map((line) => (
                              <Typography component="li" variant="subtitle1" align="center" key={line}>
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>
                        <CardActions>
                          <Button
                            sx={{ m: 2 }}
                            to={tier.linkTo}
                            component={RouterLink}
                            fullWidth
                            variant={tier.buttonVariant}
                          >
                            {tier.buttonText}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
