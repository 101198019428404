import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "./Button";
import Typography from "./Typography";
import { Icon } from "@iconify/react";
import bulbIcon from "@iconify/icons-ant-design/bulb-outline";
import calculatorIcon from "@iconify/icons-ant-design/calculator-outline";
import chartIcon from "@iconify/icons-ant-design/line-chart-outline";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.primary.darker,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(5),
    color: 'white'
  },
  number: {
    background: "none repeat scroll 0 0 #FFFFFF",
    borderRadius: "100%",
    inlineBlock: "inline-block",
    height: "80px",
    width: "80px",
    position: "relative",
    padding: "20px",
    marginBottom: theme.spacing(3),
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Smart Farmer
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <Typography color="white" variant="h5" align="center">
                  CALCULATOR
                </Typography><br/>
                <Typography align="center" color="white">Calculează și determină de ce duc lipsă animalele și primește o rețetă personalizată pentru a suplini neajunsurile.</Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <Typography color="white" variant="h5" align="center">
                  CONTACTE
                </Typography><br/>
                <Typography align="center" color="white">SRL „Altebo-Impact”<br/>
                  Republica Moldova, or. Sângerei<br/>
                  str. Independenței 1<br/>
                  Tel: +373 69455326 <br/>
                  office@smartfarmer.ro</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
