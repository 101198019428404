import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import { useAuth } from "./context/auth";
import Landing from "./pages/Landing";
import LandingLayout from "./layouts/LandingLayout";
import Calculator from "./pages/Calculator";
import Indicator from "./pages/Indicator";
import AnimalType from "./pages/Animals/AnimalType";
import Animal from "./pages/Animals/Animal";
import Forage from "./pages/Forage";
import Receipt from "./pages/Receipt";
import Vet from "./pages/Vet";
import Client from "./pages/Client";
import Supplier from "./pages/Supplier";
import Subscription from "./pages/Subscription";
import Contact from "./pages/Contact";
import About from "./pages/About";

// ----------------------------------------------------------------------

export default function Router() {
  const isAuthenticated = useAuth().authToken;

  let securedRoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "/dashboard/", element: <Navigate to="/dashboard/app" replace /> },
        { path: "/dashboard/app", element: <DashboardApp /> },
        { path: "calculator", element: <Calculator /> },
        { path: "receipt", element: <Receipt /> },
        { path: "vets", element: <Vet /> },
        { path: "clients", element: <Client /> },
        { path: "suppliers", element: <Supplier /> },
        { path: "subscriptions", element: <Subscription /> },
        { path: "contact", element: <Contact /> },
        // { path: "user", element: <User /> },
        // { path: "products", element: <Products /> },
        // { path: "blog", element: <Blog /> },
      ],
    },
    {
      path: "/admin",
      element: <DashboardLayout />,
      children: [
        { path: "/admin/indicators", element: <Indicator/> },
        { path: "/admin/animals/type", element: <AnimalType/> },
        { path: "/admin/animals/categories", element: <Animal/> },
        { path: "/admin/forages", element: <Forage/> },
        { path: "app", element: <DashboardApp /> },
        { path: "calculator", element: <Calculator /> },
        { path: "user", element: <User /> },
        { path: "products", element: <Products /> },
        { path: "blog", element: <Blog /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" replace /> },
  ];

  let routes = [
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        { path: "landing", element: <Landing /> },
        { path: "/", element: <Navigate to="/landing" /> },
        { path: "about", element: <About /> },
      ],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "landing", element: <Landing /> },
        { path: "login", element: <Login /> },

        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },

    { path: "*", element: <Navigate to="/" replace /> },
  ];

  if (isAuthenticated !== null && isAuthenticated !== "null" && isAuthenticated !== undefined) {
    routes = securedRoutes;
  }

  return useRoutes(routes);
}
