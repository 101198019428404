import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PdfIcon from "@iconify/icons-carbon/document-pdf";
import { Icon } from "@iconify/react";
export default function DailyNormMicroMacro({ animal, forages }) {
  const [animals, setAnimals] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [allForages, setAllForages] = useState([]);

  useEffect(() => {
    getAnimals();
    getIndicators();
    getForages();
  }, []);

  const getAnimals = () => {
    window.axios
      .get("animals")
      .then((response) => {
        if (response.status === 200) {
          setAnimals(response.data.data);
        }
      })
      .catch(() => {});
  };

  const getIndicators = () => {
    window.axios
      .get("indicators")
      .then((response) => {
        if (response.status === 200) {
          setIndicators(response.data.data);
        }
      })
      .catch(() => {});
  };

  const getForages = () => {
    window.axios
      .get("forages")
      .then((response) => {
        if (response.status === 200) {
          setAllForages(response.data.data);
        }
      })
      .catch(() => {});
  };

  const downloadPdf = () => {
    var windowReference = window.open();
    window.axios
      .post("/recipe/generate", { firstTable, secondTable, thirdTable, animal, forages })
      .then(({ data }) => {

          windowReference.location = data;

        windowReference.focus();
      })
      .catch((error) => {});
  };

  let currentAnimal = animals.find((a) => a.id === animal.animalId);
  let microMacroIndicators = [];
  let vitaminsIndicators = [];
  let microMacroIndicatorsDiff = [];
  let vitaminsIndicatorsDiff = [];
  let totalCost = 0;

  let firstTable = [];
  let secondTable = [];
  let thirdTable = [];
  let row = [];
  let row2 = [];

  currentAnimal &&
    currentAnimal.animalNeeds.forEach((need) => {
      if (indicators.length && indicators.find((a) => a.id === need.indicatorId).vitamine) {
        vitaminsIndicators.push(indicators.find((a) => a.id === need.indicatorId));
      } else {
        microMacroIndicators.push(indicators.find((a) => a.id === need.indicatorId));
      }
    });

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <CardHeader
            title={
              "Norma zilnică de consum a micro și macro elementelor pentru " +
              animal.animalCount +
              " " +
              (currentAnimal && currentAnimal.name)
            }
            subheader="Datele pentru vitaminele A,D3 sunt prezentate în UI(Unități internaționale), restul datelor sunt exprimate în grame"
          />
          <Box mt={2} sx={{ p: 3, pb: 3 }} dir="ltr">
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ th: { padding: "3px" } }}>
                        <TableCell align="left"></TableCell>
                        {row.push("") &&
                          microMacroIndicators.map((indicator) => {
                            row.push(indicator.name);
                            return (
                              <TableCell key={indicator.id} align="left">
                                {indicator.name}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                        <TableCell align="left">
                          {animal.animalCount} {currentAnimal && currentAnimal.name}
                        </TableCell>
                        {firstTable.push(row) &&
                          (row = []) &&
                          currentAnimal &&
                          row.push(animal.animalCount + " " + currentAnimal.name) &&
                          microMacroIndicators.map((indicator, index) => {
                            microMacroIndicatorsDiff.push(
                              _.round(
                                animal.animalCount *
                                  currentAnimal.animalNeeds.find((a) => a.indicatorId === indicator.id).quantity,
                                4
                              )
                            );
                            row.push(microMacroIndicatorsDiff[index]);
                            return <TableCell align="left">{microMacroIndicatorsDiff[index]}</TableCell>;
                          })}
                      </TableRow>
                      {forages.map((forage) => {
                        firstTable.push(row);
                        row = [];
                        row.push(
                          allForages.find((f) => f.id === forage.forageId) &&
                            allForages.find((f) => f.id === forage.forageId).name + " (" + forage.forageQuantity + "kg)"
                        );
                        return (
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                            <TableCell align="left">
                              {allForages.find((f) => f.id === forage.forageId) &&
                                allForages.find((f) => f.id === forage.forageId).name +
                                  " (" +
                                  forage.forageQuantity +
                                  "kg)"}
                            </TableCell>
                            {microMacroIndicators.map((indicator, index) => {
                              let quantity =
                                (allForages.find((f) => f.id === forage.forageId) &&
                                  allForages
                                    .find((f) => f.id === forage.forageId)
                                    .forageQuantities.find((a) => a.indicatorId === indicator.id).quantity) *
                                forage.forageQuantity *
                                10;
                              microMacroIndicatorsDiff[index] -= quantity;
                              row.push(quantity.toFixed(2));
                              return <TableCell align="left">{quantity.toFixed(2)}</TableCell>;
                            })}
                          </TableRow>
                        );
                      })}
                      {firstTable.push(row) && firstTable.push(["Diferenta", ...microMacroIndicatorsDiff]) && (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                          <TableCell align="left">Diferenta</TableCell>
                          {microMacroIndicators.map((indicator, index) => (
                            <TableCell
                              align="left"
                              style={{ color: microMacroIndicatorsDiff[index] > 0 ? "red" : "green" }}
                            >
                              {(-1 * microMacroIndicatorsDiff[index]).toFixed(2)}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <CardHeader
            title={
              "Norma zilnică de consum de vitamine pentru " +
              animal.animalCount +
              " " +
              (currentAnimal && currentAnimal.name)
            }
          />
          <Box mt={2} sx={{ p: 3, pb: 3 }} dir="ltr">
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ th: { padding: "3px" } }}>
                        <TableCell align="left"></TableCell>
                        {row2.push("") &&
                          vitaminsIndicators.map((indicator) => {
                            row2.push(indicator.name);
                            return <TableCell align="left">{indicator.name}</TableCell>;
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                        <TableCell align="left">
                          {animal.animalCount} {currentAnimal && currentAnimal.name}
                        </TableCell>
                        {secondTable.push(row2) &&
                          (row2 = []) &&
                          currentAnimal &&
                          row2.push(animal.animalCount + " " + currentAnimal.name) &&
                          vitaminsIndicators.map((indicator, index) => {
                            vitaminsIndicatorsDiff.push(
                              _.round(
                                animal.animalCount *
                                  currentAnimal.animalNeeds.find((a) => a.indicatorId === indicator.id).quantity,
                                4
                              )
                            );
                            row2.push(vitaminsIndicatorsDiff[index]);
                            return <TableCell align="left">{vitaminsIndicatorsDiff[index]}</TableCell>;
                          })}
                      </TableRow>
                      {forages.map((forage) => {
                        secondTable.push(row2);
                        row2 = [];
                        row2.push(
                          allForages.find((f) => f.id === forage.forageId) &&
                            allForages.find((f) => f.id === forage.forageId).name + " (" + forage.forageQuantity + "kg)"
                        );
                        return (
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                            <TableCell align="left">
                              {allForages.find((f) => f.id === forage.forageId) &&
                                allForages.find((f) => f.id === forage.forageId).name +
                                  " (" +
                                  forage.forageQuantity +
                                  "kg)"}
                            </TableCell>
                            {vitaminsIndicators.map((indicator, index) => {
                              let quantity =
                                ((allForages.find((f) => f.id === forage.forageId) &&
                                  allForages
                                    .find((f) => f.id === forage.forageId)
                                    .forageQuantities.find((a) => a.indicatorId === indicator.id).quantity) *
                                  forage.forageQuantity) /
                                1000;
                              vitaminsIndicatorsDiff[index] -= quantity;
                              row2.push(quantity.toFixed(2));
                              return <TableCell align="left">{quantity.toFixed(2)}</TableCell>;
                            })}
                          </TableRow>
                        );
                      })}
                      {secondTable.push(row2) && secondTable.push(["Diferenta", ...vitaminsIndicatorsDiff]) && (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                          <TableCell align="left">Diferenta</TableCell>
                          {vitaminsIndicators.map((indicator, index) => (
                            <TableCell
                              align="left"
                              style={{ color: vitaminsIndicatorsDiff[index] > 0 ? "red" : "green" }}
                            >
                              {_.round(-1 * vitaminsIndicatorsDiff[index], 6)}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <CardHeader
            title={"Costul furajelor pentru " + animal.animalCount + " " + (currentAnimal && currentAnimal.name)}
          />
          <Box mt={2} sx={{ p: 3, pb: 3 }} dir="ltr">
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ th: { padding: "3px" } }}>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">Cost Kg</TableCell>
                        <TableCell align="left">Cost Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {thirdTable.push(["", "Cost Kg", "Cost Total"]) &&
                        forages.map((forage) => {
                          let forageName =
                            allForages.find((f) => f.id === forage.forageId) &&
                            allForages.find((f) => f.id === forage.forageId).name +
                              " (" +
                              forage.forageQuantity +
                              "kg)";
                          totalCost += forage.forageQuantity * forage.forageCost;
                          thirdTable.push([forageName, forage.forageCost, forage.forageQuantity * forage.forageCost]);
                          return (
                            <TableRow
                              sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}
                            >
                              <TableCell align="left">{forageName}</TableCell>
                              <TableCell align="left">{forage.forageCost}</TableCell>
                              <TableCell align="left">{forage.forageQuantity * forage.forageCost}</TableCell>
                            </TableRow>
                          );
                        })}
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { padding: "3px" } }}>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">{totalCost}</TableCell>
                        {thirdTable.push(["Total", "", totalCost]) && false}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <Box mt={2} sx={{ pt: 3, pb: 3 }} dir="ltr">
            <Container maxWidth="xl">
              <Typography>
                <b>Remarcă:</b> Aceste norme nu ar trebui să fie considerate ca o recomandare de orientare rigidă.
                Datele cu privire la alimentarea păsărilor cu vitamine sunt foarte puține și uneori contradictorii.
                Consumul real de vitamine depinde de mulți factori, inclusiv nivelul de componente de proteine, energie
                și minerale din regimul alimentar, climatul interior, frecvența de hrănire, și altele.
              </Typography>

              <Box mt={2} textAlign="center">
                <Button
                  style={{ padding: "20px 50px" }}
                  variant="contained"
                  endIcon={<Icon icon={PdfIcon} width={30} height={30} />}
                  onClick={downloadPdf}
                >
                  <h3>Salvează în PDF</h3>
                </Button>
              </Box>
            </Container>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
