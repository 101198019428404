import { Icon } from "@iconify/react";
import indicatorIcon from "@iconify/icons-ic/outline-drag-indicator";
import animalIcon from "@iconify/icons-healthicons/animal-chicken";
import forageIcon from "@iconify/icons-carbon/wheat";
import partnersIcon from "@iconify/icons-carbon/partnership";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarAdminConfig = [
  {
    title: "Indicatori",
    path: "/admin/indicators",
    icon: getIcon(indicatorIcon),
  },
  {
    title: "Furaje",
    icon: getIcon(forageIcon),
    path: "/admin/forages",
  },
  {
    title: "Animale",
    icon: getIcon(animalIcon),
    children: [
      {
        title: "Tipuri",
        path: "/admin/animals/type",
      },
      {
        title: "Categorii",
        path: "/admin/animals/categories",
      },
    ],
  },
];

export default sidebarAdminConfig;
