import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";

import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@iconify/icons-carbon/close";
import RemoveIcon from "@iconify/icons-carbon/subtract";
import AddIcon from "@iconify/icons-carbon/add";
import { Icon } from "@iconify/react";
import { withFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import OutlinedDiv from "../../OutlinedDiv";
import TextDivider from "../../TextDivider";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const AddEdit = (props) => {
  const classes = useStyles();
  const { open, close, handleSubmit, values, handleChange, touched, errors, setFieldValue } = props;

  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    getIndicatorsTypes();
  }, []);


  const getIndicatorsTypes = () => {
    window.axios
      .get("indicators")
      .then((response) => {
        if (response.status === 200) {
          setIndicators(response.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Dialog
      open={props.open !== false}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">
        {typeof open.id !== "undefined" ? 'Editează forageul "' + open.name + '"' : "Forage nou"}
        <IconButton
          aria-label="close"
          onClick={props.close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon={CloseIcon} width={22} height={22} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" fullWidth>
                <TextField
                  name="name"
                  label="Nume furaj"
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name && touched.name}
                  helperText={!!errors.name && touched.name ? errors.name : "ex: Grau"}
                  required
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <TextDivider>Continutul Furajului</TextDivider>
                {values.forageQuantities &&
                  values.forageQuantities.map((forageNeed, index) => (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <FormControl component="fieldset" fullWidth>
                          <TextField
                            select
                            name="forageTypeId"
                            margin="dense"
                            variant="outlined"
                            label="Selecteaza un indicator"
                            value={forageNeed.indicatorId}
                            onChange={(e) => {
                              setFieldValue("forageQuantities[" + index + "]", {
                                indicatorId: e.target.value,
                                quantity: values.forageQuantities[index].quantity ?? "",
                              });
                            }}
                            error={!!errors.forageTypeId && touched.forageTypeId}
                            helperText={!!errors.forageTypeId && touched.forageTypeId ? errors.forageTypeId : ""}
                            required
                          >
                            {indicators.map((indicator) => (
                              <MenuItem
                                disabled={values.forageQuantities.map((need) => need.indicatorId).includes(indicator.id)}
                                key={indicator.id}
                                value={indicator.id}
                              >
                                {indicator.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} sm={3}>
                        <FormControl component="fieldset" fullWidth>
                          <Box display="flex" justifyContent="space-between">
                            <Box flexGrow={1}>
                              <TextField
                                fullWidth={true}
                                name="quantity"
                                label="Cantitate"
                                margin="dense"
                                variant="outlined"
                                value={forageNeed.quantity}
                                onChange={(e) =>
                                  setFieldValue("forageQuantities[" + index + "]", {
                                    indicatorId: values.forageQuantities[index].indicatorId ?? "",
                                    quantity: e.target.value,
                                  })
                                }
                                error={
                                  !!errors.forageNeed &&
                                  !!errors.forageNeed.quantity &&
                                  errors.forageNeed.quantity[index] &&
                                  touched.forageNeed.quantity[index]
                                }
                                helperText={
                                  !!errors.forageNeed &&
                                  !!errors.forageNeed.quantity &&
                                  errors.forageNeed.quantity[index] &&
                                  touched.forageNeed &&
                                  touched.forageNeed.quantity[index]
                                    ? errors.forageNeed.quantity[index]
                                    : ""
                                }
                              />
                            </Box>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <FormControl component="fieldset" fullWidth>
                          <Box display="flex" justifyContent="space-between">
                            <Box flexGrow={1}>
                              <TextField
                                fullWidth={true}
                                name="quantity"
                                label="Unitatea"
                                margin="dense"
                                variant="outlined"
                                value={
                                  indicators.find((o) => {
                                    return o.id === values.forageQuantities[index].indicatorId ?? -1;
                                  })
                                    ? indicators.find((o) => {
                                        return o.id === values.forageQuantities[index].indicatorId ?? -1;
                                      }).unit
                                    : "-"
                                }
                                disabled={true}
                              />
                            </Box>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} sm={2} spacing={0}>
                        <Box pt={2} textAlign="center">
                          {index === 0 ? (
                            <Button
                              onClick={() => {
                                setFieldValue("forageQuantities", values.forageQuantities.concat([{ quantity: '', indicatorId: '' }]));
                              }}
                            >
                              <Icon icon={AddIcon} width={22} height={22} />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                let forageQuantities = _.clone(values.forageQuantities);
                                forageQuantities.splice(
                                  index,
                                  1
                                );
                                setFieldValue("forageQuantities", forageQuantities);
                              }}
                            >
                              <Icon icon={RemoveIcon} width={22} height={22} />
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Salvează
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ open }) => ({
    id: open.id || "",
    name: open.name || "",
    forageTypeId: open.forageTypeId || "",
    forageQuantities: open.forageQuantities || [{ quantity: '', indicatorId: '' }],
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
  }),
  handleSubmit(values, { props, setErrors, resetForm }) {
    props.save(values, setErrors, resetForm);
  },
})(AddEdit);

export default FormikApp;
