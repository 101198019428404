import React from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    field: {
        width: "100%",
        "& > div": {
            padding: "15px"
        },
        marginTop: '15px'
    }
}));

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
export default ({ children, label, error }) => {
    const classes = useStyles();
    return (
        <TextField
            variant="outlined"
            label={label}
            multiline
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: InputComponent
            }}
            className={classes.field}
            error={!!error}
            helperText={!!error ? error : ""}
            inputProps={{ children: children }}
        />
    );
};
