import { Card, CardHeader, Box, Grid, Container, Avatar, Button, TextField, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import { withFormik } from "formik";
import * as Yup from "yup";

const StepTwo = ({ values, handleChange, handleSubmit, errors, touched }) => {
  const [forages, setForages] = useState([]);

  useEffect(() => {
    getForages()
  }, []);

  const getForages = () => {
    window.axios
      .get("forages", {
        params: {
          orderBy: "id",
          orderDir: "asc",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setForages(response.data.data);
        }
      })
      .catch(() => {});
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "white", border: "3px solid #637381", color: "#637381", fontWeight: "bold" }}
            aria-label="recipe"
          >
            2
          </Avatar>
        }
        title="Alimentația zilnică"
        subheader='Specifică tipurile de furaje folosite în regimul alimentar, indică cantitatea oferită zilnic, apoi apasa butonul "Adaugă".'
      />
      <Box sx={{ p: 3, pb: 3 }} dir="ltr">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid xs={12} md={4} lg={4} item direction="column" alignItems="center" justifyContent="center">
              <FormControl component="fieldset" fullWidth>
                <TextField
                  select
                  name="forageId"
                  margin="dense"
                  variant="outlined"
                  label="Tipul furajului"
                  value={values.forageId}
                  onChange={handleChange}
                  error={!!errors.forageId && touched.forageId}
                  helperText={!!errors.forageId && touched.forageId ? errors.forageId : ""}
                  required
                >
                  {forages.map((forage) => (
                    <MenuItem key={forage.id} value={forage.id}>
                      {forage.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid xs={12} md={4} lg={4} item direction="column" alignItems="center" justifyContent="center">
              <TextField
                fullWidth={true}
                name="forageQuantity"
                label="Cantitatea furajului (kg)"
                margin="dense"
                variant="outlined"
                value={values.forageQuantity}
                onChange={handleChange}
                error={!!errors.forageQuantity && touched.forageQuantity}
                helperText={!!errors.forageQuantity && touched.forageQuantity ? errors.forageQuantity : ""}
              />
            </Grid>
            <Grid xs={12} md={2} lg={2} item direction="column" alignItems="center" justifyContent="center">
              <TextField
                fullWidth={true}
                name="forageCost"
                label="Cost/Kg"
                margin="dense"
                variant="outlined"
                value={values.forageCost}
                onChange={handleChange}
                error={!!errors.forageCost && touched.forageCost}
                helperText={!!errors.forageCost && touched.forageCost ? errors.forageCost : ""}
              />
            </Grid>
            <Grid xs={12} md={2} lg={2} mt={1} item direction="column" alignItems="center" justifyContent="center">
              <FormControl fullWidth>
                <Button onClick={handleSubmit} variant="contained" style={{ padding: 15 }}>
                  Adaugă
                </Button>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2}><i style={{color:'grey'}}>(Pot fi adăugate mai multe tipuri de furaje)</i></Box>
        </Container>
      </Box>
    </Card>
  );
};

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    forageId: "",
    forageCost: "",
    forageQuantity: "",
  }),
  validationSchema: Yup.object().shape({
    forageId: Yup.string().required('Selectati tipul furajului'),
    forageQuantity: Yup.string().required('Introduceti cantitatea furajului oferit zilnic'),
    forageCost: Yup.string().required('Introduceti costul mediu unui kg de furaj'),
  }),
  handleSubmit(values, { props, setErrors, resetForm }) {
    props.add(values, setErrors, resetForm);
    resetForm();
  },
})(StepTwo);

export default FormikApp;
