import { Box, Grid, Card, Paper, Typography, CardHeader, CardContent, Container } from "@material-ui/core";
import React from "react";
import {useAuth} from "../../../context/auth";

export default function Welcome() {
  const { user } = useAuth();

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 3, pb: 3 }} dir="ltr">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                sx={{ p: 2 }}
                container
                spacing={0}
                direction="column"
                // alignItems="center"
                // justifyContent="center"
                xs={12}
                md={8}
                lg={8}
              >
                <h3>
                  {user.displayName},
                  <br /> Bine ai venit în platforma Smart Farmer!<br /><br />
                </h3>
                <Typography alignItems="flex-end"
                            // align="center"
                >
                  Folosind un sistem inteligent de calcul care vine să reducă la minim cheltuielele pentru furaje și garantează calitatea superioară a produsului final, vei câștiga mai mult timp pentru tine și afacerea ta.
                </Typography>
              </Grid>
              <Grid
                sx={{ p: 2 }}
                xs={12}
                md={4}
                lg={4}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <img src="/static/svg/control_panel.svg" alt="graph" />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </CardContent>
    </Card>
  );
}
