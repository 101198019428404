import React from 'react';
// import ProductSmokingHero from './modules/views/ProductSmokingHero';
// import AppFooter from './modules/views/AppFooter';
// import ProductHero from './modules/views/ProductHero';
// import ProductValues from './modules/views/ProductValues';
// import ProductHowItWorks from './modules/views/ProductHowItWorks';
// import ProductCTA from './modules/views/ProductCTA';



import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from '../components/Page';
import ProductCategories from "../components/landing/ProductCategories";
import AppAppBar from "../components/landing/AppAppBar";
import ProductHero from "../components/landing/ProductHero";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import ProductValues from "../components/landing/ProductValues";
import ProductHowItWorks from "../components/landing/ProductHowItWorks";
import DashboardNavbar from "../layouts/dashboard/DashboardNavbar";
import LandingNavbar from "../components/landing/LandingNavbar";
import DashboardSidebar from "../layouts/dashboard/DashboardSidebar";
import Footer from "../components/landing/Footer";

const RootStyle = styled(Page)(({ theme }) => ({

}));

export default function Landing() {
  return (
    <RootStyle title="Smart Farmer - Sporeste productivitatea afacerii tale">
      <React.Fragment>
        {/*<LogoOnlyLayout/>*/}
        {/*<AppAppBar />*/}
        {/*<LandingNavbar />*/}
        {/*<DashboardSidebar/>*/}
        <ProductHero />

        {/*<ProductCategories />*/}
        <ProductHowItWorks />
        <ProductValues />
        <Footer />
        {/*<ProductCTA />*/}
        {/*<ProductSmokingHero />*/}
        {/*<AppFooter />*/}
      </React.Fragment>
    </RootStyle>
  );
}
